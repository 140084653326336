import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Spinner from "../../components/Spinner/Spinner";
import Cover from "../../assets/img/img/cover.jpg";
import Logo from "../../assets/img/img/logo.png";
import { authUser } from "../../store/user/auth/action";
var Bcrypt = require("bcryptjs");

class Login extends Component {
  state = {
    username: "",
    password: "",
    validUsername: true,
    validPassword: true,
    rememberMe: false,
  };
  componentDidMount() {
    if (localStorage.getItem("credentials") !== null) {
      const user = JSON.parse(localStorage.getItem("credentials"));
      if (user && user.username && user.password) {
        this.setState({
          rememberMe: true,
          username: user.username,
          password: "",
        });
      }
      localStorage.removeItem("credentials");
    }
    document.addEventListener("keydown", this.handleUserKeyPressEntry);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleUserKeyPressEntry);
  }
  submitLogin = async () => {
    let validForm = true;
    if (!this.validUsername()) {
      validForm = false;
    }
    if (!this.validPassword()) {
      validForm = false;
    }
    if (validForm) {
      if (this.state.rememberMe) {
        localStorage.setItem(
          "credentials",
          JSON.stringify({
            username: this.state.username,
            password: await Bcrypt.hash(
              this.state.password,
              process.env.REACT_APP_HASH_SALT.replaceAll("/", "$")
            ),
          })
        );
      }
      console.log(process.env);
      this.props.onLogin({
        email: this.state.username,
        password: this.state.password,
      });
    }
  };

  validPassword = () => {
    if (this.state.password.length >= 4) {
      this.setState({ validPassword: true });
      return true;
    } else {
      this.setState({ validPassword: false });
      return false;
    }
  };

  validUsername = () => {
    if (this.state.username.length > 4) {
      this.setState({ validUsername: true });
      return true;
    } else {
      this.setState({ validUsername: false });
      return false;
    }
  };
  handleUserKeyPressEntry = (event) => {
    const { keyCode } = event;
    if (keyCode === 13 && !window.disabledEventListener) {
      this.submitLogin();
    }
  };
  render() {
    return (
      <React.Fragment>
        {this.props.user.loading && (
          <div className="absolute w-full  h-screen z-50 ">
            <Spinner version={1} />
          </div>
        )}
        <div className="min-h-screen flex">
          <img
            className="absolute inset-0 h-screen w-screen object-cover"
            src={Cover}
            alt=""
          />
          <div className="flex-1 flex flex-col justify-center py-12 lg:flex-none px-0 sm:px-4 md:px-8 lg:px-12 xl:px-16 2xl:px-24">
            <div
              style={{ backgroundColor: "rgba(255,255,255,0.9)" }}
              className="mx-auto p-8 h-screen sm:h-auto w-screen sm:w-8/12 md:w-6/12 lg:w-5/12  xl:w-4/12 3xl:w-3/12 absolute z-10 rounded-none sm:rounded-xl shadow top-0 mt-0 sm:mt-4 md:mt-8 lg:mt-12 xl:mt-16 2xl:mt-24"
            >
              <div >
                <img className="h-24 w-auto " src={Logo} alt="Workflow" />
                <h2 className="mt-4 text-3xl font-extrabold text-gray-900">
                  Connectez-vous à votre compte{" "}
                </h2>
              </div>
              <div className="mt-8">
                <div className="mt-6">
                  <div className="space-y-6">
                    <div>
                      <label
                        htmlFor="username"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nom d'utilisateur
                      </label>
                      <div className="mt-1">
                        <input
                          id="username"
                          name="username"
                          type="text"
                          value={this.state.username}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          onChange={(e) =>
                            this.setState({
                              username: e.target.value,
                            })
                          }
                        />
                        {!this.state.validUsername && (
                          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                            {!this.state.username
                              ? "username field is required !"
                              : "Invalid username field !"}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Mot de passe
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          value={this.state.password}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          onChange={(e) =>
                            this.setState({
                              password: e.target.value,
                            })
                          }
                        />
                        {!this.state.validPassword && (
                          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                            {!this.state.password
                              ? "Password field is required !"
                              : "Invalid password field !"}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          onChange={() =>
                            this.setState({
                              rememberMe: !this.state.rememberMe,
                            })
                          }
                          checked={this.state.rememberMe}
                          id="remember_me"
                          name="remember_me"
                          type="checkbox"
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <label
                          htmlFor="remember_me"
                          className="ml-2 block text-sm text-gray-900"
                        >
                          Souviens-toi de moi
                        </label>
                      </div>
                      <div className="text-sm"></div>
                    </div>
                    <div>
                      <button
                        onClick={() => this.submitLogin()}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Connexion
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onLogin: authUser,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
