import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getClients } from "../../store/client/getAll/action";
import Spinner from "../Spinner/Spinner";
import Footer from "./Footer";
import Header from "./Header";
import { uploadFile } from "../../store/client/edit/action";
import file from "../../assets/img/img/file.png";
import doc from "../../assets/img/img/doc.png";
import pdf from "../../assets/img/img/pdf.png";
import { CloseIcon } from "../../common/Icons/icons";
import DeleteFile from "../../common/Modals/DeleteFile";
import NoData from "../../assets/img/img/no_data.png";
import Faild from "../Faild/Faild";

const Bills = (props) => {
  const [offset, setOffset] = useState(0);
  const [row, setRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    props.getClients();
  }, []);
  const handleClients = (page) => {
    props.getClients(page);
    setOffset(page);
  };
  const handleSearch = (search) => {
    props.getClients(0, search);
  };
  const upload = (e, row) => {
    let formData = new FormData();
    const file = e.target.files[0];
    setRow(row);
    formData.set("bill", file);
    props.onUpload(formData, row._id, offset);
  };
  return (
    <>
      {showModal && (
        <DeleteFile
          handelClose={() => setShowModal(false)}
          row={row}
          offset={offset}
        />
      )}
      {props.clients.loading ? (
        <div className="absolute w-full lg:-ml-64 -mt-16  h-screen z-50 ">
          <Spinner version={1} />
        </div>
      ) : props.clients.error ? (
        <Faild />
      ) : props.clients.data.total ? (
        <div className="flex flex-col">
          <div className=" sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <Header
                  title={"Liste des bulletins de paie"}
                  title_sm={"Bulletins"}
                  disabledAdd
                  handleSearch={handleSearch}
                  searchValue={props.clients.data.search}
                />
                <div className=" hidden md:block">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Nom
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Entreprise
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Email
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {props.clients.data.rows &&
                        props.clients.data.rows.map((el, key) => (
                          <tr key={key} className="group">
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {el.name}
                            </td>
                            <td className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {el.company}
                            </td>
                            <td className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {el.email}
                            </td>
                            <td className=" px-6 py-2 whitespace-nowrap text-right text-sm font-medium">
                              {props.edit.loading && row._id === el._id ? (
                                <Spinner version={2} />
                              ) : el.fileName && el.orginName && el.mineType ? (
                                <>
                                  <div className="flex py-2 px-2 w-full">
                                    <a
                                      title="Open pdf"
                                      className="flex hover:text-blue-500 text-gray-500"
                                      href={`${process.env.REACT_APP_HOST}/pdf/${el.fileName}`}
                                      target="_blank"
                                    >
                                      <img
                                        alt="log-pdf"
                                        src={
                                          el.mineType === "application/pdf"
                                            ? pdf
                                            : el.mineType ===
                                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            ? doc
                                            : file
                                        }
                                        className="h-6 w-auto mr-2"
                                      />
                                      <span className="text-sm">
                                        {el.orginName}
                                      </span>
                                    </a>
                                    <button
                                      onClick={() => {
                                        setRow(el);
                                        setShowModal(true);
                                      }}
                                      className="right-0 w-full float-right grid items-end justify-items-end justify-end hover:text-red-500"
                                    >
                                      <CloseIcon />{" "}
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <label class=" flex flex-row items-center justify-center px-2 py-2 bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-cyan-700 hover:text-white text-cyan-700 ease-linear transition-all duration-150 ">
                                  <span class="mt-1 text-sm leading-normal mr-3">
                                    Sélectionner un fichier
                                  </span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                    />
                                  </svg>
                                  <input
                                    type="file"
                                    class="hidden"
                                    onChange={(e) => upload(e, el)}
                                  />
                                </label>
                              )}
                            </td>
                          </tr>
                        ))}

                      {/* More people... */}
                    </tbody>
                  </table>
                </div>
                <div className="block md:hidden">
                  <div className="bg-white shadow overflow-hidden sm:rounded-md">
                    <ul className="divide-y divide-gray-200">
                      {props.clients.data.rows &&
                        props.clients.data.rows.map((el, key) => (
                          <li key={key}>
                            <span className="block hover:bg-gray-50">
                              <div className="px-4 py-4 flex items-center sm:px-6">
                                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                  <div className="truncate">
                                    <div className="flex text-sm">
                                      <p className="font-medium text-gray-900 truncate">
                                        {el.name}
                                      </p>
                                      <p className="ml-1 flex-shrink-0 font-normal text-gray-500">
                                        in {el.company}
                                      </p>
                                    </div>
                                    <div className="mt-2 flex">
                                      <div className="flex items-center text-sm text-gray-500">
                                        {/* Heroicon name: solid/calendar */}
                                        <svg
                                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                          aria-hidden="true"
                                        >
                                          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                        </svg>

                                        <p>{el.email}</p>
                                      </div>
                                    </div>
                                    {el.secondEmail && (
                                      <div className="mt-2 flex">
                                        <div className="flex items-center text-sm text-gray-500">
                                          {/* Heroicon name: solid/calendar */}
                                          <svg
                                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                          </svg>

                                          <p>{el.secondEmail}</p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  {el.fileName && el.orginName && el.mineType && (
                                    <div className="mt-4 flex flex-shrink-0 sm:mt-0 sm:ml-5">
                                      <img
                                        alt="pdf-log"
                                        src={
                                          el.mineType === "application/pdf"
                                            ? pdf
                                            : el.mineType ===
                                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            ? doc
                                            : file
                                        }
                                        className="h-4 w-auto mr-2"
                                      />
                                      <span className="text-sm text-gray-500">
                                        {el.orginName}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="ml-5 flex-shrink-0">
                                  {/* Heroicon name: solid/chevron-right */}
                                  <svg
                                    className="h-5 w-5 text-gray-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <Footer
                  limit={10}
                  offset={offset}
                  total={props.clients.data.total}
                  handlePageChange={handleClients}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex h-screen p-5 items-center w-full justify-center ">
          <div className="flex flex-row h-full items-center w-full justify-center ">
            <div className="flex-shrink-0 flex items-center justify-center rounded-full bg-blue-100 mx-0 h-10 w-10">
              <img alt="no-data" src={NoData} className="w-16 h-auto" />
            </div>
            <div className=" mt-0 ml-4 text-left">
              <h3
                className="text-sm text-primary leading-6 font-medium"
                id="modal-headline"
              >
                Aucune donnée disponible!
              </h3>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    clients: state.clients,
    edit: state.edit,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getClients,
      onUpload: uploadFile,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Bills);
