import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ClockIcon } from "../../common/Icons/icons";
import { ReamainingTime } from "../../helpers/date";

const NavBar = (props) => {
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <>
      <div
        className={`fixed h-full inset-0 flex z-40 lg:hidden ${
          showSideBar ? " flex" : "hidden"
        }`}
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-75"
          aria-hidden="true"
        />

        <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-cyan-700">
          <div className="absolute top-0 right-0 -mr-12 pt-2">
            <button
              onClick={() => setShowSideBar(false)}
              type="button"
              className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="sr-only">Close sidebar</span>
              {/* Heroicon name: outline/x */}
              <svg
                className="h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex items-center flex-shrink-0 px-4">
            <span className="text-white text-lg"> Bill Manager</span>
          </div>
          <nav
            className="mt-5 flex-shrink-0 h-full divide-y divide-cyan-800 overflow-y-auto"
            aria-label="Sidebar"
          >
            <div className="px-2 space-y-1">
              <NavLink
                to="/clients"
                activeClassName="bg-cyan-800 text-white group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                className="text-cyan-100 hover:text-white hover:bg-cyan-600 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
              >
                {/* Heroicon name: outline/home */}
                <svg
                  className="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                Clients
              </NavLink>
              <NavLink
                to="/bills"
                activeClassName="bg-cyan-800 text-white group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                className="text-cyan-100 hover:text-white hover:bg-cyan-600 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
              >
                {/* Heroicon name: outline/clock */}
                <svg
                  className="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                  />
                </svg>
                Bulletin de paie
              </NavLink>
              <NavLink
                to="/template"
                activeClassName="bg-cyan-800 text-white group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                className="text-cyan-100 hover:text-white hover:bg-cyan-600 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
              >
                {/* Heroicon name: outline/scale */}
                <svg
                  className="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  ></path>
                </svg>
                Email
              </NavLink>
              <NavLink
                to="/document"
                activeClassName="bg-cyan-800 text-white group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                className="text-cyan-100 hover:text-white hover:bg-cyan-600 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
              >
                {/* Heroicon name: outline/scale */}
                <svg
                  className="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  ></path>
                </svg>
                Documents
              </NavLink>
              <NavLink
                to="/setting"
                activeClassName=" relative bg-cyan-800 text-white group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                className="relative text-cyan-100 hover:text-white hover:bg-cyan-600 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
              >
                {/* Heroicon name: outline/scale */}
                <svg
                  className="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                  ></path>
                </svg>
                Setting
                {props.hasError && (
                  <div className="absolute top-4 right-2 h-3 w-3 md:-ml-2 md:-mt-1">
                    <span
                      className={`absolute z-1 h-full w-full bg-yellow-500 rounded-full animate-ping opacity-75`}
                    >
                      {" "}
                    </span>
                    <span
                      className={`absolute h-3 z-2 w-3 bg-yellow-500 rounded-full`}
                    >
                      {" "}
                    </span>
                  </div>
                )}
              </NavLink>
            </div>
          </nav>
        </div>
        <div className="flex-shrink-0 w-14" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </div>

      <div
        className={`ml-0 lg:ml-64 flex flex-col overflow-hidden fixed top-0 inset-x-0 z-20`}
      >
        <header className="flex items-center flex-shrink-0 h-16 w-full bg-white border-b border-gray-200  text-navbar">
          <div
            id="divHeaderLeft"
            className="flex flex-0 flex-shrink-0 md:w-4/6 px-4 py-2"
          >
            <button
              type="button"
              onClick={() => setShowSideBar(true)}
              className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden"
            >
              <span className="sr-only">Open sidebar</span>
              {/* Heroicon name: outline/menu-alt-1 */}

              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </button>

            <div className="hidden lg:flex px-4 text-center justify-center text-gray-400 max-w-5/6  items-center text-sm">
              {" "}
              <ClockIcon />
              <div className="pl-2">{ReamainingTime}</div>
            </div>
          </div>
          <div
            id="divHeaderCenter"
            className="flex flex-1 justify-start pl-2"
          ></div>

          <div
            id="divHeaderRight"
            className="flex justify-end items-center p-4 md:pr-6 transition-opacity duration-100"
          >
            <div className="flex items-center">
              {/* Profil Menu */}
              <div className="relative flex-shrink-0 ">
                <span className="h-2 w-2 border-2 border-white bg-yellow-500 absolute top-1 right-1 inline-flex items-center justify-center px-1 py-1 text-white text-xs font-sm leading-none transform translate-x-1/2 -translate-y-1/2 rounded-full"></span>

                <div>
                  <button
                    onClick={() => {
                      localStorage.removeItem("token");
                      window.location.reload();
                    }}
                    className="block px-4 py-2 text-md text-gray-500 bg-gray-200 rounded-md shadow-md"
                    role="menuitem"
                    tabIndex={-1}
                    id="user-menu-item-2"
                  >
                    Se déconnecter
                  </button>
                </div>
              </div>
            </div>
            {/* end Profile Menu */}
          </div>
        </header>
      </div>
    </>
  );
};
export default NavBar;
