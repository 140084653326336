import axios from "axios";

import { getAccessToken } from "../../helpers/storage";

const basedAxios = (data, dispatch) => {
  const accessToken = data.token ? data.token : getAccessToken();
  return axios({
    method: data.method,
    url: `${process.env.REACT_APP_HOST}${data.url}`,
    data: data.data,
    headers: {
      "x-auth-token": accessToken ? `${accessToken}` : undefined,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      ...data.headers,
    },
  })
    .then((response) => {
      if (response.data && response.data.error) {
        return {
          error: true,
          errorMessage: response.data.error,
        };
      } else if (response.status === 200 || response.status === 201) {
        return {
          error: false,
          result:
            response.data && response.data.data
              ? response.data.data
              : response.data,
        };
      }
      // if (response.data && response.data.token) {
      //   refreshToken(response.data.token);
      // }
    })
    .catch((error) => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.messsage &&
        ["INVALID_TOKEN", "MISSING_TOKEN"].includes(error.response.data.message)
      ) {
        localStorage.removeItem("token");
        window.location.reload();
      }
      return {
        error: true,
        errorMessage: error.message,
      };
    });
};

export default basedAxios;
