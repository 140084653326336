import { combineReducers } from "redux";

import user from "./user/auth/reducer";
import clients from "./client/getAll/reducer";
import create from "./client/add/reducer";
import edit from "./client/edit/reducer";
import setting from './setting/get/reducer';
import doc from './document/reducer'

const rootReducer = combineReducers({
  user,
  clients,
  create,
  edit,
  setting,
  doc
});

export default rootReducer;
