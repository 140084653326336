import axios from "../../common/Axios";

export const AUTH_USER_REQUEST = "AUTH_USER_REQUEST";
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";
export const AUTH_USER_FAILURE = "AUTH_USER_FAILURE";

export const authUser = (data) => {
  return (dispatch) => {
    dispatch(authUserRequest());
    axios(
      {
        url: `/api/user/signin`,
        method: "POST",
        data,
      },
      dispatch
    ).then((res) => {
      if (res.error) {
        dispatch(authUserFailure(res.errorMessage));
      } else {
        const user = res.result;
        if (user && user.token) {
          localStorage.setItem("token", JSON.stringify(user.token));

          dispatch(
            authUserSuccess({
              ...user,
              token: user.token,
            })
          );
        } else {
          dispatch(authUserFailure("invalid data !"));
        }
      }
    });
  };
};

export const getUser = () => {
  return (dispatch) => {
    dispatch(authUserRequest());
    axios(
      {
        url: `/api/user/default`,
        method: "GET",
      },
      dispatch
    ).then((res) => {
      if (res.error) {
        dispatch(authUserFailure(res.errorMessage));
      } else {
        dispatch(authUserSuccess(res.result));
      }
    });
  };
};

export const authUserRequest = () => {
  return {
    type: AUTH_USER_REQUEST,
  };
};

export const authUserSuccess = (data, showChangeRole = false) => {
  return {
    type: AUTH_USER_SUCCESS,
    payload: { data, showChangeRole },
  };
};

export const authUserFailure = (error) => {
  return {
    type: AUTH_USER_FAILURE,
    payload: error,
  };
};
