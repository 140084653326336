const files = {
  ["Status à Simple Propriétaire"]: {
    fileName: "STATUTS-SAS.docx",  
    fields: [
      {
        key: "STATUS_TYPE",
        title: "Type de status",
        type: "select",
        options: ["SAS", "SARL"],
        default: "SAS"
      },
      {
        key: "COMPANY_NAME",
        title: "Nom de société",
        default:""
      },
      {
        key: "COMPANY_ADDRESS",
        title: "Adresse de la société",
        default: ""
      },
      {
        key: "CAPITALE_NUMBER",
        title: "Capitale de société en chiffres (euros)",
        default: "1000",
      },
      {
        key: "CAPITALE_WORD",
        title: "Capitale de société en lettre (euros)",
        default: "mille"
      },
      {
        key: "PARTNER_NAME",
        title: "Nom de l'associé",
        default: "Monsieur "
      },
      {
        key: "PARTNER_BIRTHDAY",
        title: "Date de naissance de l'associé",
        default: "10/10/1970",
      },
      {
        key: "BIRTHDAY_PLACE",
        title: "Lieu de naissance de l'associé",
        default: "",
      },
      {
        key: "BIRTHDAY_PAYER",
        title: "Payer de naissance de l'associé",
        default: "",
      },
      {
        key: "PARTNER_NATIONALITE",
        title: "Nationalité de l'associé",
        default: "",
      },
      {
        key: "PARTNER_ADDRESS",
        title: "Adress de l'associé",
        default: "",
      },
      {
        key: "COMPANY_OBJECT",
        title: "Objet de société",
        type: "textarea",
        default: "",
      },
      {
        key: "ACTION_NUMBER",
        title: "Nombre d'actions",
        default: "100"
      },
      {
        key: "ACTION_NUMBER_WORD",
        title: "Nombre d'actions",
        default: "Cent"
      },
      {
        key: "CONTRAT_PLACE",
        title: "Contrat fait à (Emplacement)",
        default: ""
      },
      {
        key: "CONTRAT_DATE",
        title: "Date de contrat",
        default: ""
      },
    ],
  },
  "Status à Deux Partenaires": {
    fileName: "STATUTS-SAS-2.docx", 
    fields: [
      {
        key: "STATUS_TYPE",
        title: "Type de status",
        type: "select",
        options: ["SAS", "SARL"],
        default: "SAS"
      },
      {
        key: "label_1",
        type:"label",
        title: "Information Sur La Société"
      },
      {
        key: "COMPANY_NAME",
        title: "Nom de société",
        default:""
      },
      {
        key: "COMPANY_ADDRESS",
        title: "Adresse de la société",
        default: ""
      },
      {
        key: "CAPITALE_NUMBER",
        title: "Capitale de société en chiffres (euros)",
        default: "1000",
      },
      {
        key: "CAPITALE_WORD",
        title: "Capitale de société en lettre (euros)",
        default: "mille"
      },
      {
        key: "COMPANY_OBJECT",
        title: "Objet de société",
        type: "textarea",
        default: "",
      },
      {
        key: "ACTION_NUMBER",
        title: "Nombre d'actions",
        default: "100"
      },
      {
        key: "ACTION_NUMBER_WORD",
        title: "Nombre d'actions",
        default: "Cent"
      },
      {
        key: "label_1",
        type:"label",
        title: "Information Sur La Première Associée"
      },
      {
        key: "PARTNER_NAME_1",
        title: "Nom de l'associé",
        default: "Monsieur "
      },
      {
        key: "PARTNER_BIRTHDAY_1",
        title: "Date de naissance de l'associé",
        default: "10/10/1970",
      },
      {
        key: "BIRTHDAY_PLACE_1",
        title: "Lieu de naissance de l'associé",
        default: "",
      },
      {
        key: "BIRTHDAY_PAYER_1",
        title: "Payer de naissance de l'associé",
        default: "",
      },
      {
        key: "PARTNER_NATIONALITE_1",
        title: "Nationalité de l'associé",
        default: "",
      },
      {
        key: "PARTNER_ADDRESS_1",
        title: "Adress de l'associé",
        default: "",
      },
      {
        key: "ACTION_NUMBER_1",
        title: "Nomber d'action (Parts)",
        default: "50",
      },
      {
        key: "ACTION_NUMBER_1_WORD",
        title: "Nombre d'action en lettre (Parts)",
        default: "Cinquante",
      },
      {
        key: "C_PART_1",
        title: "Part de l'associé (euros)",
        default: "500",
      },
      {
        key: "C_PART_1_WORD",
        title: "Nomber d'action (euros)",
        default: "Cinq cents",
      },
      {
        key: "label_2",
        type:"label",
        title: "Information Sur La Deuxième Associée"
      },
      {
        key: "PARTNER_NAME_2",
        title: "Nom de l'associé",
        default: "Monsieur "
      },
      {
        key: "PARTNER_BIRTHDAY_2",
        title: "Date de naissance de l'associé",
        default: "10/10/1970",
      },
      {
        key: "BIRTHDAY_PLACE_2",
        title: "Lieu de naissance de l'associé",
        default: "",
      },
      {
        key: "BIRTHDAY_PAYER_2",
        title: "Payer de naissance de l'associé",
        default: "",
      },
      {
        key: "PARTNER_NATIONALITE_2",
        title: "Nationalité de l'associé",
        default: "",
      },
      {
        key: "PARTNER_ADDRESS_2",
        title: "Adress de l'associé",
        default: "",
      },
      {
        key: "ACTION_NUMBER_2",
        title: "Nomber d'action (Parts)",
        default: "50",
      },
      {
        key: "ACTION_NUMBER_2_WORD",
        title: "Nombre d'action en lettre (Parts)",
        default: "Cinquante",
      },
      {
        key: "C_PART_2",
        title: "Part de l'associé (euros)",
        default: "500",
      },
      {
        key: "C_PART_2_WORD",
        title: "Nomber d'action (euros)",
        default: "Cinq cents",
      },
      {
        key: "CONTRAT_PLACE",
        title: "Contrat fait à (Emplacement)",
        default: ""
      },
      {
        key: "CONTRAT_DATE",
        title: "Date de contrat",
        default: ""
      },
    ], 
   
  },
  "Contrat":{
    fileName: "CONTRAT.docx", 
    fields: [
      {
        key: "label_1",
        type:"label",
        title: "Information Sur La Société"
      },
      {
        key: "C_TYPE",
        title: "Type de status",
        type: "select",
        options: ["SAS", "SARL"],
        default: "SAS"
      },
      {
        key: "C_NAME",
        title: "Nom de société",
        default:""
      },
      {
        key: "C_ADDRESS",
        title: "Adresse de la société",
        default: ""
      },
      {
        key: "C_CAPITAL",
        title: "Capitale de société en chiffres (euros)",
        default: "5000",
      },
      {
        key: "C_IMA",
        title: "Numero immatriculé",
        default: "",
      },
      {
        key: "NAF",
        title: "Code NAF",
        default: "",
      },
      {
        key: "C_CEO",
        title: "Représenté par",
        default: "Monsieur ",
      },
      {
        key: "label_1",
        type:"label",
        title: "Information Sur L'employée"
      },
      {
        key: "E_NAME",
        title: "Nom de l'employée",
        default: "Monsieur ",
      },
      {
        key: "E_BIRTHDAY",
        title: "Date de naissance",
        default: "",
      },
      {
        key: "E_B_PLACE",
        title: "Lieu de naissance",
        default: "",
      },
      {
        key: "E_N",
        title: "Nationalité",
        default: "",
      },
      {
        key: "E_ADDRESS",
        title: "Adresse de l'employé",
        default: "",
      },
      {
        key: "E_S_S",
        title: "Numéro de sécurité social ",
        default: "",
      },
      {
        key: "label_1",
        type:"label",
        title: "Information Sur Le Contrat"
      },
      {
        key: "C_POSTE",
        title: "Poste d'employé",
        default: "",
      },
      {
        key: "E_ROLE",
        title: "Role d'employé (Sous forme de tiré)",
        type:"textarea",
        default: "",
      },
      {
        key: "C_ESSAI",
        title: "Dure de d'essai",
        default: "deux mois",
      },
      {
        key: "C_HORAIRE",
        title: "Heures de travail par semaine",
        default: "35 heures",
      },
      {
        key: "E_SALAIRE",
        title: "Salaire mensuelle brute (euros)",
        default: "",
      },
      {
        key: "BEGIN_DATE",
        title: "Date prise de fonction",
        default: "1 janvier 2022",
      },
      {
        key: "C_PLACE",
        title: "Contrat fait à (emplacement)",
        default: "France",
      },
      {
        key: "C_DATE",
        title: "Date de creation de contrat",
        default: "1 janvier 2022",
      },
    ] 
  }
};
export default files