import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getClients } from "../../store/client/getAll/action";
import Spinner from "../Spinner/Spinner";
import Footer from "./Footer";
import Header from "./Header";
import EditClient from "../../common/Modals/EditClient";
import { DeleteIcon, EditIcon } from "../../common/Icons/icons";
import DeleteClient from "../../common/Modals/DeleteClient";
import NoData from "../../assets/img/img/no_data.png";
import Faild from "../Faild/Faild";

const Clients = (props) => {
  const [offset, setOffset] = useState(0);
  const [row, setRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  useEffect(() => {
    props.getClients();
  }, []);
  const handleClients = (page) => {
    props.getClients(page);
    setOffset(page);
  };
  const handleSearch = (search) => {
    props.getClients(0, search);
  };
  return (
    <>
      {showModal && (
        <EditClient
          handleClose={() => setShowModal(false)}
          row={row}
          offset={offset}
        />
      )}
      {showDeleteModal && (
        <DeleteClient
          handelClose={() => setShowDeleteModal(false)}
          row={row}
          offset={offset}
        />
      )}
      {props.clients.loading ? (
        <div className="absolute w-full lg:-ml-64 -mt-16  h-screen z-50 ">
          <Spinner version={1} />
        </div>
      ) : props.clients.error ? (
        <Faild />
      ) : (
        <div className="flex flex-col">
          <div className="sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block max-w-ful lg:min-w-full px-0 sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <Header
                  title={"Liste des clients"}
                  title_sm={"Clients"}
                  handleSearch={handleSearch}
                  searchValue={props.clients.data.search}
                />
                {props.clients.data.total ? (
                  <>
                    <div className=" hidden md:block">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Nom
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Entreprise
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Email
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Email en CC
                            </th>
                            <th scope="col" className="relative px-6 py-3">
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>

                        <tbody className="bg-white divide-y divide-gray-200">
                          {props.clients.data.rows &&
                            props.clients.data.rows.map((el, key) => (
                              <tr
                                key={key}
                                className={
                                  key % 2 === 0 ? "bg-white" : "bg-gray-50"
                                }
                              >
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {el.name}
                                </td>
                                <td className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {el.company}
                                </td>
                                <td className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {el.email}
                                </td>
                                <td className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {el.secondEmail}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                  <div className="flex">
                                    <button
                                      onClick={() => {
                                        setRow(el);
                                        setShowModal(true);
                                      }}
                                      className="text-gray-500 hover:text-blue-500 mr-2"
                                    >
                                      <EditIcon />
                                    </button>
                                    <button
                                      onClick={() => {
                                        setRow(el);
                                        setShowDeleteModal(true);
                                      }}
                                      className="text-gray-500 hover:text-red-500"
                                    >
                                      <DeleteIcon />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}

                          {/* More people... */}
                        </tbody>
                      </table>
                    </div>
                    <div className="block md:hidden">
                      <div className="bg-white shadow overflow-hidden sm:rounded-md">
                        <ul className="divide-y divide-gray-200">
                          {props.clients.data.rows &&
                            props.clients.data.rows.map((el, key) => (
                              <li key={key}>
                                <span className="block hover:bg-gray-50">
                                  <div className="px-4 py-4 flex items-center sm:px-6">
                                    <div className="min-w-0 flex-1 flex items-center justify-between">
                                      <div className="truncate">
                                        <div className="flex text-sm">
                                          <p className="font-medium text-gray-900 truncate">
                                            {el.name}
                                          </p>
                                          <p className="ml-1 flex-shrink-0 font-normal text-gray-500">
                                            en {el.company}
                                          </p>
                                        </div>
                                        <div className="mt-2 flex">
                                          <div className="flex items-center text-sm text-gray-500">
                                            {/* Heroicon name: solid/calendar */}
                                            <svg
                                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                              fill="currentColor"
                                              aria-hidden="true"
                                            >
                                              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                            </svg>

                                            <p>{el.email}</p>
                                          </div>
                                        </div>
                                        {el.secondEmail && (
                                          <div className="mt-2 flex">
                                            <div className="flex items-center text-sm text-gray-500">
                                              {/* Heroicon name: solid/calendar */}
                                              <svg
                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                              >
                                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                              </svg>

                                              <p>{el.secondEmail}</p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                                        <div className="flex overflow-hidden -space-x-1">
                                          <div className="flex">
                                            <button
                                              titre="Modifier"
                                              onClick={() => {
                                                setRow(el);
                                                setShowModal(true);
                                              }}
                                              className="text-gray-500 hover:text-blue-500 mr-2"
                                            >
                                              <EditIcon />
                                            </button>
                                            <button
                                              title="Supprimer"
                                              onClick={() => {
                                                setRow(el);
                                                setShowDeleteModal(true);
                                              }}
                                              className="text-gray-500 hover:text-red-500"
                                            >
                                              <DeleteIcon />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    <Footer
                      limit={10}
                      offset={offset}
                      total={props.clients.data.total}
                      handlePageChange={handleClients}
                    />
                  </>
                ) : (
                  <div className="flex h-screen p-5 items-center w-full justify-center ">
                    <div className="flex flex-row h-full items-center w-full justify-center ">
                      <div className="flex-shrink-0 flex items-center justify-center rounded-full bg-blue-100 mx-0 h-10 w-10">
                        <img
                          alt="no-data"
                          src={NoData}
                          className="w-16 h-auto"
                        />
                      </div>
                      <div className=" mt-0 ml-4 text-left">
                        <h3
                          className="text-sm text-primary leading-6 font-medium"
                          id="modal-headline"
                        >
                          Aucune donnée disponible!
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    clients: state.clients,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getClients,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Clients);
