import {
  ADD_CLIENT_REQUEST,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILURE,
  ADD_CLIENT_RESET,
} from "./action";

const initialState = {
  loading: false,
  added: false,
  error: "",
};

const addClientReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_CLIENT_SUCCESS:
      return {
        loading: false,
        added: true,
        error: "",
      };
    case ADD_CLIENT_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    case ADD_CLIENT_RESET:
      return { loading: false, added: false, error: "" };
    default:
      return state;
  }
};

export default addClientReducer;
