import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { createDoc } from "../../store/document/action";
import Spinner from "../Spinner/Spinner";
import Files from "./doc";
import Doc from "../../assets/img/img/doc.png";
import Faild from "../Faild/Faild";

class Template extends Component {
  state = {
    document: "",
    data: {},
  };
  handelSubmit = () => {
    let data = this.state.data;
    if (data.ACTION_NUMBER && data.CAPITALE_NUMBER) {
      data["VALEUR_NOMINALE"] =
        parseInt(data.CAPITALE_NUMBER) / parseInt(data.ACTION_NUMBER);
    }
    if (data.CAPITALE_WORD) {
      data.CAPITALE_WORD_UPER = data.CAPITALE_WORD.toUpperCase();
    }
    if (data.ACTION_NUMBER_2_WORD && data.ACTION_NUMBER_1_WORD) {
      data.ACTION_NUMBER_2_WORD = data.ACTION_NUMBER_2_WORD.toUpperCase();
      data.ACTION_NUMBER_1_WORD = data.ACTION_NUMBER_1_WORD.toUpperCase();
    }
    if (data.C_PART_1_WORD && data.C_PART_2_WORD) {
      data.C_PART_1_WORD = data.C_PART_1_WORD.toUpperCase();
      data.C_PART_2_WORD = data.C_PART_2_WORD.toUpperCase();
    }
    if (data.STATUS_TYPE) {
      data.STATUS_TYPE_COMPLETE = this.getCompanyTypeComplete(data.STATUS_TYPE);
    }
    if (data.ACTION_NUMBER_2 && data.ACTION_NUMBER_1) {
      data.ACTION_NUMBER_2_BEGIN = parseInt(data.ACTION_NUMBER_1) + 1;
    }
    if(data.C_HORAIRE){
      data.C_HORAIRE_M = (parseFloat(data.C_HORAIRE) * 4.333).toFixed(2)
    }
    this.props.createDoc(Files[this.state.document].fileName, data);
  };
  getCompanyTypeComplete = (type) => {
    if (type === "SAS") return "Société par Actions Simplifiée";
    else return "Société à Responsabilité Limité";
  };
  render() {
    return (
      <>
        {this.props.doc.loading && (
          <div className="absolute w-full lg:-ml-64 -mt-16  h-screen z-50 ">
            <Spinner version={1} />
          </div>
        )}
        {this.props.doc.error ? (
          <Faild />
        ) : !this.state.document ? (
          <>
            <div className="mb-10">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Sélectionnez le nom de document
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Sélectionnez un document puis remplissez les champs pour pouvoir
                le générer.
              </p>
            </div>
            <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl-grid-cols-5 grid-flow-row gap-4">
              {Object.keys(Files).map((el, key) => (
                <button
                  className="border relative group w-full rounded overflow-hidden shadow-lg hover:opacity-100 opacity-75 transition-opacity duration-500 ease-in-out"
                  onClick={() => {
                    let data = {};
                    Files[el].fields.map((item) => {
                      data[item.key] = item.default;
                    });
                    this.setState({
                      document: el,
                      data,
                    });
                  }}
                >
                  <div className="w-full flex text-center justify-center items-center">
                    <img
                      className="h-48 w-auto mt-4"
                      src={Doc}
                      alt="Mountain"
                    />
                  </div>
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">{el}</div>
                  </div>
                </button>
              ))}
            </div>
          </>
        ) : (
          <div className="space-y-8 divide-y divide-gray-200 m-4 p-4 border border-gray-200 rounded-md shadow-md">
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                <div className=" group flex text-left">
                  <button
                    onClick={() => this.setState({ document: null })}
                    className="flex space-x-8 bg-transparent border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white text-center py-2 px-4 rounded"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M10 19l-7-7m0 0l7-7m-7 7h18"
                      />
                    </svg>
                    Retour
                  </button>
                </div>
                <div className="space-y-6 sm:space-y-5">
                  {Files[this.state.document].fields.map((el, key) => {
                    if (el.type === "label") {
                      return (
                        <div className="sm:pt-5 sm:items-start w-full">
                          <label
                            className={`"text-lg leading-6 font-medium text-gray-900 w-full"`}
                          >
                            {el.title}
                          </label>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={key}
                          className={`${
                            !key ? "sm:border-t sm:border-gray-200" : ""
                          } sm:pt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start`}
                        >
                          <label
                            htmlFor="first-name"
                            className={`${
                              el.type === "label"
                                ? "text-lg leading-6 font-medium text-gray-900 w-full"
                                : "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            } `}
                          >
                            {el.title}
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            {el.type === "select" ? (
                              <select
                                onChange={(e) =>
                                  this.setState({
                                    data: {
                                      ...this.state.data,
                                      [el.key]: e.target.value,
                                    },
                                  })
                                }
                                value={this.state.data[el.key]}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              >
                                {el.options.map((el, key) => (
                                  <option key={key} value={el}>
                                    {el}
                                  </option>
                                ))}
                              </select>
                            ) : el.type === "textarea" ? (
                              <textarea
                                value={this.state.data[el.key]}
                                onChange={(e) =>
                                  this.setState({
                                    data: {
                                      ...this.state.data,
                                      [el.key]: e.target.value,
                                    },
                                  })
                                }
                                type="text"
                                row={2}
                                name="first-name"
                                id="first-name"
                                autoComplete="given-name"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              />
                            ) : (
                              <input
                                value={this.state.data[el.key]}
                                onChange={(e) =>
                                  this.setState({
                                    data: {
                                      ...this.state.data,
                                      [el.key]: e.target.value,
                                    },
                                  })
                                }
                                type="text"
                                name="first-name"
                                id="first-name"
                                autoComplete="given-name"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              />
                            )}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  onClick={() => this.setState({ data: {} })}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Annuler
                </button>
                <button
                  onClick={() => this.handelSubmit()}
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-700 hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Telecharger
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    doc: state.doc,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createDoc,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Template);
