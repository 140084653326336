import { bindActionCreators } from "redux";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";

import { authUserSuccess, getUser } from "../../store/user/auth/action";
import { jsonParser } from "../../helpers/parser";
import Login from "../../containers/Login/Login";
import Spinner from "../Spinner/Spinner";
import SideBar from "../SIdeBar/SideBar";
import NavBar from "../NavBar/NavBar";
import Clients from "../Clients/Clients";
import Bills from "../Clients/Bill";
import Template from "../Template/Template";
import Documents from "../Documents/Document";
import Setting from "../Setting/Setting";
import { getSetting } from "../../store/setting/get/action";
import { isValidDay, setRemainingTime } from "../../helpers/date";
import { MailType } from "../../constant";

class WeHoop extends Component {
  state = {
    showAnimation: false,
    hasConfigError: false,
  };
  componentDidMount() {
    if (
      localStorage.getItem("token") &&
      !this.props.user.loading &&
      !this.props.user.data.id
    ) {
      this.props.onGetUser();
    }
    if (typeof window !== "undefined") {
      window.addEventListener("storage", this.localStorageUpdated);
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.user.loading &&
      !this.props.user.loading &&
      this.props.user.data.id &&
      !this.props.setting.data.length &&
      !this.props.setting.loading
    ) {
      this.props.getSetting();
    } else if (prevProps.setting.loading && !this.props.setting.loading) {
      const settingOfBill = this.props.setting.data.find(el => el.name === MailType.SEND);
      this.setState({
        hasConfigError: !isValidDay(settingOfBill.day),
      });
      setRemainingTime(settingOfBill.day);
    }
  }
  localStorageUpdated = () => {
    // check token in storage
    const token = jsonParser(localStorage.getItem("token"));
    if (
      (!token ||
        token !== this.props.user.data.token ||
        typeof token !== "string") &&
      window.location.pathname !== "/login"
    ) {
      localStorage.removeItem("token");
      window.location.reload();
    } else if (
      token &&
      typeof token === "string" &&
      window.location.pathname === "/login"
    ) {
      window.location.reload();
    }
  };

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("storage", this.localStorageUpdated);
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.user.loading ? (
          <div className="absolute w-full h-full z-50 ">
            <Spinner version={1} />
          </div>
        ) : localStorage.getItem("token") ? (
          <div className="h-screen bg-primary select-none cursor-default overflow-hidden">
            <div className="h-full w-full">
              <SideBar hasError={this.state.hasConfigError} />
              <div className="lg:pl-64 flex flex-col flex-1  overflow-y-auto h-full">
                <NavBar hasError={this.state.hasConfigError} />
                <main className="flex-1 pt-16">
                  <div className="lg:mt-8 lg:px-8 px-2 mt-2">
                    <Switch>
                      <Route exact path="/clients" component={Clients} />
                      <Route exact path="/bills" component={Bills} />
                      <Route exact path="/template" component={Template} />
                      <Route exact path="/document" component={Documents} />
                      <Route exact path="/setting" component={Setting} />
                      <Redirect to="/clients" />
                    </Switch>
                  </div>
                </main>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Redirect to="/login" />
            </Switch>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
    requestError: state.requestError,
    setting: state.setting,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      isAuth: authUserSuccess,
      onGetUser: getUser,
      getSetting: getSetting,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WeHoop));
