import React from 'react';

import PageButton from '../../common/Pagination/PageButton';
import PageNumber from '../../common/Pagination/PageNumber';

const Footer = ({ total, limit, offset, handlePageChange }) => {
    return (
        <div className='flex justify-between items-center h-12 px-4 flex-shrink-0 bg-whit border-t border-gray-200 text-window-icon'>
            <div className='hidden md:block text-window-muted'>
                {!total
                    ? ''
                    : `${offset + 1} - ${
                          offset + limit > total
                              ? total
                              : offset + limit
                      }  de ${total}`}
            </div>
            <div className='flex justify-between items-center w-full md:w-auto space-x-5'>
                <PageButton
                    orientation={'left'}
                    pagePosition={offset}
                    pageCount={limit}
                    totalPageCount={total}
                    handlePageChange={handlePageChange}
                />
                <PageNumber
                    pagePosition={offset}
                    pageCount={limit}
                    totalPageCount={total}
                    handlePageChange={handlePageChange}
                />
                <PageButton
                    orientation={'right'}
                    pagePosition={offset}
                    pageCount={limit}
                    totalPageCount={total}
                    handlePageChange={handlePageChange}
                />
            </div>
        </div>
    );
};


export default Footer;
