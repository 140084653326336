import React from "react";
import { FailedIcon } from "../../common/Icons/icons";

const Faild = () => (
  <div className="h-screen -mt-16 flex justify-center">
    <div className="bg-white rounded-lg p-10 flex items-center justify-between">
      <div className="flex flex-col items-center justify-between">
        <div className="flex-shrink-0  mb-4 flex items-center justify-center rounded-full bg-red-100 mx-0 h-12 w-12">
          <FailedIcon />
        </div>
        <h2 className="text-2xl mb-4 text-gray-800 text-center font-bold">
          Désolé, quelque chose s'est mal passé
        </h2>
        <p>Actualisez la page et réessayez.</p>
      </div>
    </div>
  </div>
);
export default Faild;
