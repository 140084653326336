import axios from "../../common/Axios";

export const GET_ALL_CLIENTS_REQUEST = "GET_ALL_CLIENTS_REQUEST";
export const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS";
export const GET_ALL_CLIENTS_FAILURE = "GET_ALL_CLIENTS_FAILURE";


export const getClients = (offset=0, search=null) => {
  return (dispatch) => {
    dispatch(clientsRequest());
    axios(
      {
        url: `/api/client/all?offset=${offset}${search? `&search=${search}`: ''}`,
        method: "GET",
      },
      dispatch
    ).then((res) => {
      if (res.error) {
        dispatch(clientsFailure(res.errorMessage));
      } else {
        dispatch(clientsSuccess({...res.result, search}));
      }
    });
  };
};

export const clientsRequest = () => {
  return {
    type: GET_ALL_CLIENTS_REQUEST,
  };
};

export const clientsSuccess = (data) => {
  return {
    type: GET_ALL_CLIENTS_SUCCESS,
    payload: data,
  };
};

export const clientsFailure = (error) => {
  return {
    type: GET_ALL_CLIENTS_FAILURE,
    payload: error,
  };
};
