import axios from "../common/Axios";

export const CREATE_DOC_REQUEST = "CREATE_DOC_REQUEST";
export const CREATE_DOC_SUCCESS = "CREATE_DOC_SUCCESS";
export const CREATE_DOC_FAILURE = "CREATE_DOC_FAILURE";

export const createDoc = (fileName, params) => {
  return (dispatch) => {
    dispatch(docRequest());
    axios(
      {
        url: `/api/doc`,
        method: "POST",
        data: {fileName, params}
      },
      dispatch
    ).then((res) => {
      if (res.error) {
        dispatch(docFailure(res.errorMessage));
      } else {
        debugger;
        window.open(`${process.env.REACT_APP_EXTERNAL_HOST}${res.result.url}`, '_blank', 'noopener,noreferrer')
        dispatch(docSuccess(res.result));
      }
    });
  };
};

export const docRequest = () => {
  return {
    type: CREATE_DOC_REQUEST,
  };
};

export const docSuccess = (data) => {
  return {
    type: CREATE_DOC_SUCCESS,
    payload: data,
  };
};

export const docFailure = (error) => {
  return {
    type: CREATE_DOC_FAILURE,
    payload: error,
  };
};
