import moment from "moment";
import { monthNames } from "../constant";

export let ReamainingTime = "";
export const MonthYear = () => {
  const today = moment();
  return `${monthNames[today.month()]} ${today.year()}`;
};

export const PrevMonthYear = () => {
  const today = moment();
  return `${monthNames[today.month() === 0 ? 11 : today.month() - 1]} ${[
    today.month() === 0 ? today.year() - 1 : today.year(),
  ]}`;
};
export const DayMonthYear = (day) => {
  const today = moment();
  let year;
  let month;
  if (day < today.date()) {
    month = monthNames[today.month() === 11 ? 0 : today.month() + 1];
    year = today.month() === 11 ? today.year() + 1 : today.year();
  } else {
    year = today.year();
    month = monthNames[today.month()];
  }
  return `${day} ${month} ${year} 08:00 AM`;
};
export const isValidDay = (day) => {
  const today = moment();
  var date = moment(
    `${day}.${today.month() + 1}.${today.year()}`,
    "DD.MM.YYYY"
  );
  return date.isValid();
};
export const setRemainingTime = (day) => {
  const now = moment().local("tn");
  let end;
  if (now.date() == day && now.hours() >= 8) {
    end = moment(
      `${day}.${now.month() + 2 <= 12 ? now.month() + 2 : now.month() - 10}.${
        now.month() + 2 <= 12 ? now.year() : now.year() + 1
      } 08:00`,
      "DD.MM.YYYY hh:mm"
    );
  } else {
    end = moment(
      `${day}.${now.month() + 1}.${now.year()} 08:00`,
      "DD.MM.YYYY hh:mm"
    );
  }
  const diffDay = end.diff(now, "days");
  const diffHours = end.diff(now, "hours") % 24;
  ReamainingTime = `L'envoi du courrier va démarrer dans ${
    diffDay ? (diffDay === 1 ? `${diffDay} jour` : `${diffDay} jours`) : ""
  }${diffHours && diffDay ? ` et ` : ""}${
    diffHours
      ? diffHours === 1
        ? `${diffHours} heure`
        : `${diffHours} heures`
      : ""
  }`;
};
export const dateRange = () => {
  const today = new Date();
  const lastDayOfMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0
  ).getDate();
  const range = [];
  for (let i = 1; i <= lastDayOfMonth; i++) {
    range.push(i.toString());
  }
  return range;
};
