import axios from "../../common/Axios";

import {clientsSuccess} from "../getAll/action"

export const ADD_CLIENT_REQUEST = "ADD_CLIENT_REQUEST";
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_FAILURE = "ADD_CLIENT_FAILURE";
export const ADD_CLIENT_RESET = "ADD_CLIENT_RESET";



export const addClient = (data) => {
  return (dispatch) => {
    dispatch(addClientRequest());
    axios(
      {
        url: `/api/client/create`,
        method: "POST",
        data
      },
      dispatch
    ).then((res) => {
      if (res.error) {
        dispatch(addClientFailure(res.errorMessage));
      } else {
        dispatch(clientsSuccess(res.result))
        dispatch(addClientSuccess());
      }
    });
  };
};

export const addClientRequest = () => {
	return {
		type: ADD_CLIENT_REQUEST,
	};
};

export const addClientSuccess = (data) => {
	return {
		type: ADD_CLIENT_SUCCESS,
		payload: data,
	};
};

export const addClientFailure = (error) => {
	return {
		type: ADD_CLIENT_FAILURE,
		payload: error,
	};
};

export const addClientReset = () => {
	return {
		type: ADD_CLIENT_RESET,
	};
};
