import axios from "../../common/Axios";

export const GET_SETTING_REQUEST = "GET_SETTING_REQUEST";
export const GET_SETTING_SUCCESS = "GET_SETTING_SUCCESS";
export const GET_SETTING_FAILURE = "GET_SETTING_FAILURE";

export const getSetting = () => {
  return (dispatch) => {
    dispatch(settingRequest());
    axios(
      {
        url: `/api/setting`,
        method: "GET",
      },
      dispatch
    ).then((res) => {
      if (res.error) {
        dispatch(settingFailure(res.errorMessage));
      } else {
        dispatch(settingSuccess(res.result));
      }
    });
  };
};
export const updateSetting = (data, id) => {
  return (dispatch) => {
    dispatch(settingRequest());
    axios(
      {
        url: `/api/setting/update/${id}`,
        method: "POST",
        data: data,
      },
      dispatch
    ).then((res) => {
      if (res.error) {
        dispatch(settingFailure(res.errorMessage));
      } else {
        dispatch(settingSuccess(res.result));
      }
    });
  };
};

export const settingRequest = () => {
  return {
    type: GET_SETTING_REQUEST,
  };
};

export const settingSuccess = (data) => {
  return {
    type: GET_SETTING_SUCCESS,
    payload: data,
  };
};

export const settingFailure = (error) => {
  return {
    type: GET_SETTING_FAILURE,
    payload: error,
  };
};
