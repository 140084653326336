import {
  EDIT_CLIENT_REQUEST,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_FAILURE,
  EDIT_CLIENT_RESET,
} from "./action";

const initialState = {
  loading: false,
  updated: false,
  error: "",
};

const editClientReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_CLIENT_SUCCESS:
      return {
        loading: false,
        updated: true,
        error: "",
      };
    case EDIT_CLIENT_FAILURE:
      return {
        loading: false,
        updated: false,
        error: action.payload,
      };
    case EDIT_CLIENT_RESET:
      return {
        loading: false,
        updated: false,
        error: "",
      };
    default:
      return state;
  }
};

export default editClientReducer;
