import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { DayMonthYear, MonthYear, PrevMonthYear } from "../../helpers/date";
import { getSetting, updateSetting } from "../../store/setting/get/action";
import Confirmation from "../../assets/img/img/Confirmation.png";
import Validation from "../../assets/img/img/Validation.png";
import { MailDescription, MailType } from "../../constant";
import Factures from "../../assets/img/img/Factures.png";
import { ClockIcon } from "../../common/Icons/icons";
import RichText from "../../common/RichText";
import Spinner from "../Spinner/Spinner";
import Faild from "../Faild/Faild";

class Template extends Component {
  state = {
    setting: null,
    content: "",
    object: "",
    error: "",
    updated: false,
  };
  componentDidMount() {
    if (!this.props.setting.data.loading && !this.props.setting.data.length) {
      this.props.getSetting();
    } else if (this.state.setting) {
      this.loadData();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.setting !== this.state.setting && this.state.setting) {
      this.loadData();
    }
  }
  loadData = () => {
    if (this.state.setting.object || this.state.setting.content) {
      this.setState({
        ...this.state.setting,
        content: this.state.setting.content,
        object: this.state.setting.object,
      });
    }
  };
  handelSubmit = () => {
    if (!this.state.content || !this.state.object) {
      this.setState({ error: "You must fill all the fields!! " });
    } else {
      this.props.updateSetting(
        {
          ...this.state.setting,
          object: this.state.object,
          content: this.state.content,
        },
        this.state.setting.name
      );
    }
  };
  hasChange = () => {
    if (
      this.state.content !== this.state.setting.content ||
      this.state.object !== this.state.setting.object
    ) {
      return true;
    }
    return false;
  };
  render() {
    return (
      <>
        {this.props.setting.loading ? (
          <div className="absolute w-full lg:-ml-64 -mt-16  h-screen z-50 ">
            <Spinner version={1} />
          </div>
        ) : this.props.setting.error ? (
          <Faild />
        ) : this.state.setting ? (
          <div className="h-full flex flex-col pb-8 overflow-y-auto text-sm md:text-md px-2 md:px-0">
            <div className="mt-2 h-10 w-full flex justify-between items-center">
              <div className="group flex text-left">
                <button
                  onClick={() =>
                    this.setState({
                      setting: null,
                      content: null,
                      object: null,
                    })
                  }
                  className=" flex mr-4 space-x-3 bg-transparent border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white text-center py-2 px-4 rounded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    />
                  </svg>
                  Retour
                </button>
              </div>
              <button
                onClick={() => this.handelSubmit()}
                className="relative shadow-md text-white flex justify-center items-center h-8 mr-3 w-auto px-3 bg-blue-500 rounded-full md:border-1 border-blue-500 hover:bg-blue-400 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-navbar focus:ring-navbar transition duration-100"
              >
                Enregistrer
                {this.hasChange() && (
                  <div className="absolute top-0 right-2 h-3 w-3 md:-ml-2 md:-mt-1">
                    <span
                      className={`absolute z-1 h-full w-full bg-yellow-500 rounded-full animate-ping opacity-75`}
                    >
                      {" "}
                    </span>
                    <span
                      className={`absolute h-3 z-2 w-3 bg-yellow-500 rounded-full`}
                    >
                      {" "}
                    </span>
                  </div>
                )}
              </button>
            </div>
            <div className="mt-6">
              <div className="inline-flex text-center">
                <label
                  htmlFor="about"
                  className="block lg:text-sm text-lg leading-5 font-medium text-primary"
                >
                  Objet du courrier
                </label>
              </div>
              <input
                value={this.state.object}
                onChange={(e) => this.setState({ object: e.target.value })}
                type={"text"}
                className={` border-gary-200  focus:border-blue-300 mt-1 form-input bg-secondary text-primary block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none  transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                placeholder={"Mail Object"}
              ></input>
            </div>
            {this.state.content || this.state.updated ? (
              <div className="-my-2 overflow-x-auto -mx-8">
                <RichText
                  handelChange={(val) =>
                    this.setState({ content: val, updated: true })
                  }
                  value={this.state.content}
                />
              </div>
            ) : null}
            <div className="mt-24 border-t">
              <div className="inline-flex text-center pt-8 ">
                <label
                  htmlFor="about"
                  className="text-lg leading-6 font-medium text-gray-900 w-full"
                >
                  Mots clés
                </label>
              </div>
              <p className="w-full mt-1 max-w-2xl text-sm text-gray-500">
                Les mots clés doivent être comme je l'ai indiqué ci-dessous
                entre {`{{ }}`}.
              </p>
              <div className="sm:pt-5 sm:grid sm:grid-cols-3 sm:gap-2 sm:items-start">
                <label
                  className={` "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"`}
                >
                  {`{{ CLIENT_NAME }} :`}
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">Nom du client</div>
              </div>
              <div className="sm:pt-5 sm:grid sm:grid-cols-3 sm:gap-2 sm:items-start">
                <label
                  className={` "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"`}
                >
                  {`{{ COPANY_NAME }} :`}
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">Nom du société</div>
              </div>
              <div className="sm:pt-5 sm:grid sm:grid-cols-3 sm:gap-2 sm:items-start">
                <label
                  className={` "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"`}
                >
                  {`{{ MONTH_DATE }} :`}
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  Mois en cours (ex: {MonthYear()} )
                </div>
              </div>
              <div className="sm:pt-5 sm:grid sm:grid-cols-3 sm:gap-2 sm:items-start">
                <label
                  className={` "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"`}
                >
                  {`{{ OLD_MONTH_DATE }} :`}
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  Mois précédent (ex: {PrevMonthYear()} )
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl-grid-cols-5 grid-flow-row gap-4">
            {this.props.setting.data.map((el, key) => (
              <button
                className="border relative group w-full rounded overflow-hidden shadow-lg hover:opacity-100 opacity-75 transition-opacity duration-500 ease-in-out"
                onClick={() => {
                  this.setState({
                    setting: el,
                  });
                }}
              >
                <div className="w-full flex text-center justify-center items-center">
                  <img
                    className="h-48 w-auto mt-4"
                    src={
                      el.name === MailType.SEND
                        ? Factures
                        : el.name === MailType.VALIDATION
                        ? Validation
                        : Confirmation
                    }
                    alt="Mountain"
                  />
                </div>
                <div className="px-6 py-4 text-left">
                  <div className="font-bold text-xl mb-2">{el.name == MailType.SEND ? 'Bulletin de paie': el.name}</div>
                  <p class="text-gray-700 text-base h-40">
                    {MailDescription[el.name].replace("_DATE", el.day)}
                  </p>
                </div>
                <div className="pb-4 pt-2 flex px-4 text-left justify-left text-gray-400 items-center text-sm">
                  {" "}
                  <ClockIcon />
                  <div className="pl-2">{DayMonthYear(el.day)}</div>
                </div>
              </button>
            ))}
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    setting: state.setting,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSetting,
      updateSetting,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Template);
