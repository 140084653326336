import React, { Component } from "react";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
class RichText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      val: "",
    };
  }

  render() {
    return (
      <div className="px-8 mt-6">
        <div className="inline-flex text-center">
          <label
            htmlFor="about"
            className="block lg:text-sm text-lg leading-5 font-medium text-primary"
          >
           Contenu du courrier
          </label>
        </div>
        <div
          className={`'border-primary focus:shadow-outline-blue focus:border-blue-300' text-editor rounded-md   focus:border-blue-300 mt-1 border `}
        >
          
            <CKEditor
              editor={ClassicEditor}
              data={this.props.value}
              onChange={(event, editor) => this.props.handelChange(editor.getData())}
            />
         
        </div>
      </div>
    );
  }
}

export default RichText;
