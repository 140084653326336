export const monthNames = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
];
export const MailType = {
  VALIDATION: "Validation",
  SEND: "Factures",
  CONFIRMATION: "Confirmation",
};
export const MailDescription ={
  [MailType.CONFIRMATION]: "Modèle d'email de confirmation qui sera envoyé automatiquement le _DATE de chaque mois pour vérifier auprès des clients s'il y a des changements avant d'envoyer la fiche de paie.",
  [MailType.SEND]: "Modèle d'e-mail de Bulletin de paie qui sera envoyé automatiquement le _DATE de chaque mois avec un bulletin de paie en pièce jointe.",
  [MailType.VALIDATION]: "Modèle d'email de validation qui sera envoyé automatiquement le _DATE de chaque mois pour valider la fiche de paie du mois précédent auprès des clients."
}
