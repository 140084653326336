import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { getSetting, updateSetting } from "../../store/setting/get/action";
import Spinner from "../Spinner/Spinner";
import { dateRange, isValidDay } from "../../helpers/date";
import Faild from "../Faild/Faild";
import { MailType } from "../../constant";

class Setting extends Component {
  state = {
    data: {},
    hasConfigError: {},
    hasData: false,
  };
  componentDidMount() {
    if (!this.props.setting.data.loading && !this.props.setting.data.length) {
      this.props.getSetting();
    } else {
      this.loadData();
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.setting.loading && !this.props.setting.loading) {
      this.loadData();
    }
  }
  loadData = () => {
    let data = {};
    let hasConfigError = {};
    for (const item of this.props.setting.data) {
      data[item.name] = { isActive: item.isActive, day: item.day };
      hasConfigError[item.name] = !isValidDay(item.day);
    }
    this.setState({
      data,
      hasConfigError,
      hasData: true,
    });
  };
  handelSubmit = (el) => {
    this.props.updateSetting(
      {
        ...el,
        isActive: this.state.data[el.name].isActive,
        day: this.state.data[el.name].day,
      },
      el.name
    );
  };
  hasChange = (el) => {
    if (
      this.state.data[el.name].isActive !== el.isActive ||
      this.state.data[el.name].day !== el.day
    ) {
      return true;
    }
    return false;
  };
  render() {
    return (
      <>
        {this.props.setting.loading ? (
          <div className="absolute w-full lg:-ml-64 -mt-16  h-screen z-50 ">
            <Spinner version={1} />
          </div>
        ) : this.props.setting.error ? (
          <Faild />
        ) : this.state.hasData ? (
          <div className="w-full flex flex-col space-y-6 overflow-auto pb-8 px-2 md:px-0">
            {this.props.setting.data.map((el, key) => (
              <div
                key={key}
                className="flex flex-col border rounded-md shadow-md pb-4 px-4"
              >
                <div className="border-b w-full flex items-center justify-between h-12">
                  <div className="inherit">{el.name === MailType.SEND? 'Bulletins de paie': el.name}</div>
                  <div className=" w-full flex justify-items-end items-end justify-end">
                    <button
                      onClick={() => this.handelSubmit(el)}
                      className="relative shadow-md text-white flex justify-center items-center h-8 mr-3 w-auto px-3 bg-blue-500 rounded-full md:border-1 border-blue-500 hover:bg-blue-400 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-navbar focus:ring-navbar transition duration-100"
                    >
                      Enregistrer
                      {this.hasChange(el) && (
                        <div className="absolute top-0 right-2 h-3 w-3 md:-ml-2 md:-mt-1">
                          <span
                            className={`absolute z-1 h-full w-full bg-yellow-500 rounded-full animate-ping opacity-75`}
                          >
                            {" "}
                          </span>
                          <span
                            className={`absolute h-3 z-2 w-3 bg-yellow-500 rounded-full`}
                          >
                            {" "}
                          </span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
                {this.state.hasConfigError[el.name] && (
                  <div className="rounded-md bg-red-50 p-4 mt-6">
                    <div className="flex ">
                      <div className="flex-shrink-0">
                        {/* Heroicon name: solid/x-circle */}
                        <svg
                          className="h-5 w-5 text-red-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">
                          Il y avait un erreur avec le date d'envoi
                        </h3>
                        <div className="mt-2 text-sm text-red-700">
                          <ul role="list" className="list-disc pl-5 space-y-1">
                            <li>
                              la date d'envoi n'est pas valable vous devez la
                              changer.
                            </li>
                            <li>
                              Vérifiez le jour d'envoi, peut-être pas inclus
                              dans ce mois
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-6 w-full">
                  <div className="text-center items-center flex justify-between">
                    <label
                      htmlFor="about"
                      className="inherit block text-sm leading-6 font-normal md:font-medium text-gray-700"
                    >
                      Activer/Désactiver le service mail :
                    </label>
                    <label
                      htmlFor={`toogle-${el.name}`}
                      className="flex items-center cursor-pointer ml-3"
                    >
                      {/* toggle */}
                      <div className="relative">
                        {/* input */}
                        <input
                          id={`toogle-${el.name}`}
                          onChange={() =>
                            this.setState({
                              data: {
                                ...this.state.data,
                                [el.name]: {
                                  ...this.state.data[el.name],
                                  isActive: !this.state.data[el.name].isActive,
                                },
                              },
                            })
                          }
                          type="checkbox"
                          className="sr-only"
                          checked={this.state.data[el.name].isActive}
                        />
                        {/* line */}
                        <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner" />
                        {/* dot */}
                        <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition" />
                      </div>
                      {/* label */}
                      <div className="ml-3 text-gray-700 font-medium">
                        {this.state.data[el.name].isActive
                          ? "Activé"
                          : "Desactivé"}
                      </div>
                    </label>
                  </div>
                </div>
                <div className="mt-6 w-full">
                  <div className="text-center items-center flex justify-between">
                    <label
                      htmlFor="about"
                      className="inherit block text-sm leading-6 font-normal md:font-medium text-gray-700"
                    >
                      Sélectionner la date d'envoi :
                    </label>
                    <select
                      className="md:w-full w-20 ml-3 max-w-xs  border-gray-200  focus:border-blue-300 mt-1 form-input bg-secondary text-gray-500 block py-2 px-3 border rounded-md shadow-sm focus:outline-none  transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      // className=" max-w-xs block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      value={this.state.data[el.name].day}
                      onChange={(e) =>
                        this.setState({
                          data: {
                            ...this.state.data,
                            [el.name]: {
                              ...this.state.data[el.name],
                              day: e.target.value,
                            },
                          },
                        })
                      }
                    >
                      {dateRange().map((item, key) => (
                        <option value={item} key={key}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* <div className="mt-16">
              <p className="text-sm text-gray-500">
                Le service de courrier est{" "}
                {this.state.isActive ? (
                  <>
                    <strong>activé</strong> et les e-mails sont programmés pour
                    être envoyés le{" "}
                    <strong>
                      {" "}
                      {this.state.day}{" "}
                      {MonthYear()} à
                      8h00 AM{" "}
                    </strong>
                  </>
                ) : (
                  <strong>desactivé</strong>
                )}{" "}
                .
              </p>
            </div> */}
              </div>
            ))}
          </div>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    setting: state.setting,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSetting,
      updateSetting,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Setting);
