import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './index';

const configureStore = (initialState) => {
  return createStore(rootReducer, initialState, applyMiddleware(thunk));
};

export default configureStore;
