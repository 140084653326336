import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { CloseIcon, FailedIcon } from "../Icons/icons";
import Spinner from "../../components/Spinner/Spinner";
import { editClient, editReset } from "../../store/client/edit/action";

function EditClient(props) {
  const [name, setName] = useState(props.row.name);
  const [company, setCompany] = useState(props.row.company);
  const [email, setEmail] = useState(props.row.email);
  const [secondEmail, setSecondEmail] = useState(props.row.secondEmail);
  const [error, setEror] = useState("");
  const { handleClose, editClient, edit, offset, editReset } = props;

  useEffect(() => {
    editReset();
    const el = document.getElementById("user-modal");
    if (el) {
      el.classList.remove("scale-x-0");
      el.classList.add("scale-x-1");
    }
  }, []);

  const handleAdd = () => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!name) {
      setEror("Name is required");
      return;
    } else if (!company) {
      setEror("Company name is required");
      return;
    } else if (!email) {
      setEror("Email is required");
      return;
    } else if (!regex.test(email)) {
      setEror("Email address invalide!");
      return;
    } else if (secondEmail && !regex.test(secondEmail)) {
      setEror("Second email invalide!");
      return;
    } else if (email === secondEmail) {
      setEror(
        "Second email must be different to the first email (You can keep this input empty)"
      );
      return;
    } else {
      setEror("");
      editClient(
        { name, company, email, secondEmail, _id: props.row._id },
        offset
      );
    }
  };
  return (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className=" inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <div
          id="user-modal"
          className="sm:align-middle max-w-full md:max-w-lg w-full inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all ease-out duration-300 scale-x-0"
        >
          <header className="space-y-1 h-12 relative w-full top-0 py-3 px-4 bg-cyan-700 sm:px-6">
            <div className="flex items-center justify-between space-x-3">
              <h2 className="text-lg leading-7 font-medium text-white">
                Modifier un client
              </h2>
              <div className="h-7 flex items-center">
                <button
                  onClick={() => handleClose()}
                  aria-label="Close panel"
                  className="text-indigo-200 hover:text-white transition ease-in-out duration-150"
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
          </header>
          {error && (
            <div
              className="mt-4 ml-4 mr-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Echec de l'entrée</strong>
              <br />
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          {!edit.loading && !edit.error && !edit.updated ? (
            <React.Fragment>
              <div
                className="z-10  pb-4 origin-top right-0 left-0 "
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <div className=" w-full">
                  <div className="shadow sm:rounded-md sm:overflow-hidden">
                    <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-6">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Nom de client
                          </label>
                          <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            name="first-name"
                            id="first-name"
                            autoComplete="given-name"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-6">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Nom de l'entreprise
                          </label>
                          <input
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            type="text"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-6">
                          <label
                            htmlFor="email-address"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Adresse email
                          </label>
                          <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="text"
                            name="email-address"
                            id="email-address"
                            autoComplete="email"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-6">
                          <label
                            htmlFor="email-address"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Deuxième adresse email
                          </label>
                          <input
                            value={secondEmail}
                            onChange={(e) => setSecondEmail(e.target.value)}
                            type="text"
                            name="email-address"
                            id="email-address"
                            autoComplete="email"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse">
                <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                  <button
                    onClick={() => handleAdd()}
                    className="inline-flex justify-center w-full border-primary rounded-md border px-4 py-2 bg-cyan-700 text-base leading-6 font-medium text-white shadow-sm hover:bg-cyan-600 focus:outline-none focus:border-cyan-900 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  >
                    Modifier
                  </button>
                </span>
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                  <button
                    onClick={() => handleClose()}
                    type="button"
                    className="inline-flex justify-center text-primary border-primary w-full rounded-md border px-4 py-2 bg-primary text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  >
                    Annuler
                  </button>
                </span>
              </div>
            </React.Fragment>
          ) : edit.loading ? (
            <div className="relative w-full h-96 z-50 ">
              <Spinner version={1} />
            </div>
          ) : edit.updated ? (
            <div className="h-96  flex justify-center">
              <div className="bg-white rounded-lg p-10 flex items-center justify-between">
                <div>
                  <svg
                    className="mb-4 h-20 w-20 text-green-500 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    {" "}
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <h2 className="text-2xl mb-4 text-gray-800 text-center font-bold">
                    Mise à jour avec succès
                  </h2>
                </div>
              </div>
            </div>
          ) : (
            <div className="h-96  flex justify-center">
              <div className="bg-white rounded-lg p-10 flex items-center justify-between">
                <div className="flex flex-col items-center justify-between">
                  <div className="flex-shrink-0  mb-4 flex items-center justify-center rounded-full bg-red-100 mx-0 h-12 w-12">
                    <FailedIcon />
                  </div>
                  <h2 className="text-2xl mb-4 text-gray-800 text-center font-bold">
                    Désolé, quelque chose s'est mal passé
                  </h2>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    edit: state.edit,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editClient,
      editReset,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(EditClient);
