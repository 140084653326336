export const parseNumber = (string) =>
  string && !isNaN(parseInt(string)) ? parseInt(string) : null;

export const jsonParser = (string) => {
  if (string) {
    try {
      const result = JSON.parse(string);
      return result;
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
};
