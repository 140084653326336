import axios from "../../common/Axios";

import { clientsSuccess } from "../getAll/action";

export const EDIT_CLIENT_REQUEST = "EDIT_CLIENT_REQUEST";
export const EDIT_CLIENT_SUCCESS = "EDIT_CLIENT_SUCCESS";
export const EDIT_CLIENT_FAILURE = "EDIT_CLIENT_FAILURE";
export const EDIT_CLIENT_RESET = "EDIT_CLIENT_RESET";

export const editClient = (data , offset) => {
  return (dispatch) => {
    dispatch(editRequest());
    axios(
      {
        url: `/api/client/update?offset=${offset}`,
        method: "POST",
        data,
      },
      dispatch
    ).then((res) => {
      if (res.error) {
        dispatch(editFailure(res.errorMessage));
      } else {
        dispatch(clientsSuccess(res.result));
        dispatch(editSuccess());
      }
    });
  };
};
export const uploadFile = (data, id, offset) =>{
  return (dispatch) => {
    dispatch(editRequest());
    axios(
      {
        url: `/api/client/upload/${id}?offset=${offset}`,
        method: "PUT",
        data,
      },
      dispatch
    ).then((res) => {
      if (res.error) {
        dispatch(editFailure(res.errorMessage));
      } else {
        dispatch(clientsSuccess(res.result));
        dispatch(editSuccess());
      }
    });
  };
}
export const deleteFile = (id, offset) =>{
  return (dispatch) => {
    dispatch(editRequest());
    axios(
      {
        url: `/api/client/upload/${id}?offset=${offset}`,
        method: "DELETE",
      },
      dispatch
    ).then((res) => {
      if (res.error) {
        dispatch(editFailure(res.errorMessage));
      } else {
        dispatch(clientsSuccess(res.result));
        dispatch(editSuccess());
      }
    });
  };
}
export const deleteClient = (id, offset) =>{
  return (dispatch) => {
    dispatch(editRequest());
    axios(
      {
        url: `/api/client/delete/${id}?offset=${offset}`,
        method: "DELETE",
      },
      dispatch
    ).then((res) => {
      if (res.error) {
        dispatch(editFailure(res.errorMessage));
      } else {
        dispatch(clientsSuccess(res.result));
        dispatch(editSuccess());
      }
    });
  };
}
export const editRequest = () => {
  return {
    type: EDIT_CLIENT_REQUEST,
  };
};

export const editSuccess = (data) => {
  return {
    type: EDIT_CLIENT_SUCCESS,
    payload: data,
  };
};

export const editFailure = (error) => {
  return {
    type: EDIT_CLIENT_FAILURE,
    payload: error,
  };
};

export const editReset = (error) => {
  return {
    type: EDIT_CLIENT_RESET,
    payload: error,
  };
};