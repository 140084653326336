import React, { useState } from "react";

const PageNumber = ({
  pagePosition,
  pageCount,
  totalPageCount,
  handlePageChange,
}) => {
  const [showPageMenu, setShowPageMenu] = useState(false);

  function reactToClickPage(pageNumber) {
    setShowPageMenu((showPageMenu) => !showPageMenu);
    handlePageChange(pageNumber * pageCount);
  }

  if (totalPageCount !== 0) {
    if (pageCount !== 0) {
      return (
        <div className="relative">
          <button
            onClick={() => setShowPageMenu((showPageMenu) => !showPageMenu)}
            className="px-4 text-window-muted rounded-md bg-white hover:text-window-icon-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-window focus:ring-window transition duration-100"
          >
            {pagePosition / pageCount +
              1 +
              " / " +
              Math.ceil(totalPageCount / pageCount)}
          </button>
          {!showPageMenu ? (
            ""
          ) : (
            <div className="absolute grid grid-cols-2 grid-flow-row gap-4 shadow-xl border text-center items-center bottom-0 left-1/2 m-auto w-28 mb-10 p-2 bg-white rounded-3xl transform -translate-x-1/2">
              {[...Array(Math.ceil(totalPageCount / pageCount))].map(
                (page, index) => (
                  <button
                    key={page}
                    onClick={() => reactToClickPage(index)}
                    className={`${
                      pagePosition / pageCount == index
                        ? "bg-blue-400 text-white"
                        : "hover:bg-gray-200"
                    } px-3 py-2  border shadow-md rounded-md`}
                  >
                    {index + 1}
                  </button>
                )
              )}
            </div>
          )}
        </div>
      );
    } else {
      return "";
    }
  } else {
    return (
      <div className="px-4 text-window-icon-muted rounded-full">0 / 0</div>
    );
  }
};

export default PageNumber;
