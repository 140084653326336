import React, { useState } from "react";

import { AddIcon, SearchIcon } from "../../common/Icons/icons";
import AddClients from "../../common/Modals/AddClients";

const Header = (props) => {
  const { handleSearch, searchValue, disabledAdd, title, title_sm } = props;
  const [search, setSearch] = useState(searchValue ? searchValue : "");
  const [showAddModal, setShowAddModal] = useState(false);

  const searchAction = () => {
    if (search || (searchValue && !search)) {
      handleSearch(search);
      setSearch("");
    }
  };

  return (
    <>
      {showAddModal && (
        <AddClients handleClose={() => setShowAddModal(false)} />
      )}
      <div className="flex justify-between items-center h-12 px-4 bg-gray-50 text-window shadow-md">
        <div className="flex text-lg pr-3 overflow-hidden">
          <h2 className="hidden md:block">{title}</h2>
          <h2 className="block md:hidden">{title_sm}</h2>
        </div>

        <div className="flex float-right w-4/6 md:w-3/6 text-window-icon justify-center items-center text-center">
          <form
            onSubmit={searchAction}
            className="group relative flex flex-1 items-center lg:max-w-full rounded-full"
          >
            <input
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              type="text"
              className={`flex-1 h-8  p-2 pl-4 pr-6 bg-transparent rounded-full z-10 border focus:border-none focus:outline-none focus:ring-2 focus:ring-window transition duration-700 ease-in-out`}
              placeholder="Recherche de client..."
              id="input-table-search"
            />

            <button
              type="submit"
              className="absolute flex justify-center items-center right-0 p-1 mb-1 cursor-pointer hover:text-window-icon-accent transition duration-100 z-10"
            >
              <SearchIcon class={"h-5 w-5"} viewBox={"-3 -5 24 28"} />
            </button>
          </form>
          {/* add menu  */}
          {!disabledAdd && (
          <div className="relative inline-block flex-shrink-0 text-left">
            <div>
              <button
              title="Ajouter un client"
                className="ml-3 rounded-full bg-window hover:text-window-icon-accent flex items-center focus:outline-none focus:ring-2 focus:ring-window focus:ring-offset-2 focus:ring-offset-window transition duration-100"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
                onClick={() => setShowAddModal(true)}
              >
                <AddIcon class={"h-5 w-5"} />
              </button>
            </div>
          </div>
          )}
          {/* end add menu */}
        </div>
      </div>
    </>
  );
};

export default Header;
